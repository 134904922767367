import { getWeb3Obj, getWeb3ContractObject, getContract } from "src/utils";
import { lotteryContract } from "src/constants";
import lotteryABI from "src/ABI/lotteryABI.json";

export const getOnGoingLotteryDataBlockchain = async ({
  account,
  library,
  page,
}) => {
  try {
    const web3 = await getWeb3Obj();
    const contract = await getWeb3ContractObject(lotteryABI, lotteryContract);
    // console.log("contract---", contract);
    const previousAmount = await contract.methods
      .totalAmountUntillLastLottery()
      .call();
    const lotteryId = await contract.methods.currentLotteryId().call();
    const id = page ? page?.toString() : lotteryId?.toString();
    const viewLotteryDetail = await contract.methods.viewLottery(id).call();
    const currentTicketNumber = await contract.methods.currentTicketId().call();
    const checkTotalPrice = await contract.methods
      .calculateTotalPriceForBulkTickets(
        viewLotteryDetail.discountDivisor?.toString(),
        viewLotteryDetail.priceTicketInCake?.toString(),
        "1"
      )
      .call();
    const priceinwei = web3.utils.fromWei(checkTotalPrice?.toString());
    let userinfo = {};
    if (account) {
      const createObj = getContract(
        lotteryContract,
        lotteryABI,
        library,
        account
      );
      const viewUserInfoForLottery = await createObj.viewUserInfoForLotteryId(
        account,
        id,
        0,
        100
      );
      userinfo = viewUserInfoForLottery;
    }
    return {
      currentTicketValue: currentTicketNumber,
      allData: viewLotteryDetail,
      ticketNumber: priceinwei,
      ticketData: account ? userinfo : null,
      lotteryId: lotteryId,
      previousPendingAmount: web3.utils.fromWei(previousAmount?.toString()),
    };
  } catch (error) {
    console.log(error);
  }
};
