import React, { useEffect, useState } from "react";
import { makeStyles, Box, Typography, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import TopBar from "./TopBar";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#0D0D0D",
    position: "relative",
    zIndex: "9",
  },
  MainLayout: {
    minHeight: "calc(100vh - 415px)",
  },
  disclaimerBox: {
    // position: "absolute",
    bottom: "0px",
    position: "sticky",
    padding: "10px",
    backgroundColor: "#dda12a",
    zIndex: "9999",
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const [isChecked, setisChecked] = useState(false);

  useEffect(() => {
    setisChecked(window.localStorage.getItem("isFlag"));
  }, [window.localStorage.getItem("isFlag")]);

  return (
    <div className={classes.root}>
      <TopBar />
      <div
        style={
          history.location.pathname !== "/"
            ? { display: "block" }
            : { display: "none" }
        }
      ></div>

      <div className={classes.MainLayout}>{children}</div>
      {!isChecked && (
        <Box className={classes.disclaimerBox}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="body2">
              <span style={{ fontWeight: "600" }}>Disclaimer:</span> This is a
              test domain of our upcoming project and is not for public use.
              Only authorized users for testing purpose are allowed to join and
              test. If you have landed here by mistake, then kindly logout and
              leave this page now.
            </Typography>
            <Box>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  setisChecked(true);
                  window.localStorage.setItem("isFlag", true);
                }}
              >
                Ok
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default MainLayout;
