export const NetworkContextName = "NETWORK";
export const ACTIVE_NETWORK = 97;
export const lotteryContract = "0x46C6aA68fd53Dcd68D16d6fa4032D0215C85DD5B"; //BNB testnet
export const RPC_URL = "https://data-seed-prebsc-1-s1.binance.org:8545/";
export const chainTokenAddress = "0x84b9B910527Ad5C03A9Ca831909E21e236EA7b06";
export const randomContractAddress =
  "0x9Cf2884860769c10242384DE872FcCC579C1E7Cd";
export const currencyUnit = "BUSD";
export const lotterrCretionPrice = {
  min: 0.005,
  max: 50,
};

export const lotteryNumberrange = {
  min: 1000000,
  max: 1999999,
};

//---#ACTIVE-NETWORK-DETAILS#---//
export const NetworkDetails = [
  {
    chainId: "0x61",
    chainName: "Smart Chain - Testnet",
    nativeCurrency: {
      name: "tBNB",
      symbol: "tBNB",
      decimals: 18,
    },
    rpcUrls: [RPC_URL],
    blockExplorerUrls: ["https://testnet.bscscan.com"],
  },
];
