import axios from "axios";
import apiConfigs from "src/apiConfig";

export const postAPIHandler = async ({ endPoint, dataToSend, paramsData }) => {
  try {
    return await axios({
      method: "POST",
      url: apiConfigs[endPoint],
      headers: {
        token: window.localStorage.getItem("token"),
      },
      data: dataToSend ? dataToSend : null,
      params: paramsData ? paramsData : null,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getAPIHandler = async ({ endPoint, id, source }) => {
  try {
    return await axios({
      method: "GET",
      url: id ? `${apiConfigs[endPoint]}/${id}` : apiConfigs[endPoint],
      headers: {
        token: window.localStorage.getItem("token"),
      },
      cancelToken: source ? source.token : null,
    });
  } catch (error) {
    console.log(error);
  }
};
