import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Box,
  Container,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Logo from "./../../component/Logo";
import { UserContext } from "src/context/User";
import ConnectModal from "src/component/ConnectModal";
import { useWeb3React } from "@web3-react/core";
import { numberCompactFormat, sortAddress } from "src/utils";
import { currencyUnit } from "src/constants";
import AddCustomToken from "src/component/AddCustomToken";
const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: " 5px 0px",
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    width: "60px",
    marginBottom: "15px",
  },
  drawerContainer: {
    padding: "20px 0px 20px 20px",
    height: "100%",
    background: "#000",
    color: "#fff",
    width: "260px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  drawericon: {
    color: "#000",
    marginLeft: "0px !important",
    fontSize: "25px",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
  },
}));

export default function Header() {
  const { toolbar, drawerContainer, drawericon, logoDrawer, mainHeader } =
    useStyles();
  const history = useHistory();
  const location = useLocation();
  const user = useContext(UserContext);
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const { account } = useWeb3React();
  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);
  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
          {location.pathname === "/" && <AddCustomToken />}
          {location.pathname !== "/" && (
            <Button
              variant="contained"
              to="/"
              color="secondary"
              component={Link}
              style={{ whiteSpace: "pre" }}
            >
              Back
            </Button>
          )}
        </Box>

        <Box className="centerLogoBox">{femmecubatorLogo}</Box>
        <Box display="flex" justifyContent="flex-end" alignItems="flex-start">
          {getDrawerChoices()}
        </Box>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));
    //mobile
    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <img className={logoDrawer} src="images/logo.png" alt="" />
            {getDrawerChoices()}
          </div>
        </Drawer>

        <Box display="flex" justifyContent="space-between">
          {location.pathname !== "/" && (
            <Button
              variant="contained"
              to="/"
              color="secondary"
              component={Link}
              style={{ whiteSpace: "pre" }}
            >
              Back
            </Button>
          )}
        </Box>
        <Box display="flex" alignItems="center">
          <IconButton
            className={drawericon}
            {...{
              edge: "start",
              color: "inherit",
              "aria-label": "menu",
              "aria-haspopup": "true",
              onClick: handleDrawerOpen,
            }}
          >
            <MenuIcon
              width="60px"
              height="60px"
              style={{ color: "#fec712", fontSize: "26px" }}
            />
          </IconButton>
        </Box>
      </Toolbar>
    );
  };
  //mobile end
  const getDrawerChoices = () => {
    return (
      <>
        {account && (
          <Button
            variant="contained"
            className="TopbarButton"
            to="/"
            color="secondary"
            component={Link}
            style={{ whiteSpace: "pre", padding: "10px 22px" }}
          >
            <img
              src="images/buttonicon_1.png"
              alt="iamge"
              style={{ marginRight: "6px" }}
            />{" "}
            {user &&
              user.tokenBalance &&
              `${numberCompactFormat(
                user.tokenBalance || 0
              )} ${currencyUnit}`}{" "}
          </Button>
        )}

        <Button
          variant="contained"
          className="TopbarButton"
          color="secondary"
          onClick={() => history.push("/how-to-play")}
          style={{ whiteSpace: "pre", padding: "10px 22px" }}
        >
          <img src="images/buttonicon_3.png" alt="iamge" /> How to Play
        </Button>
        {!account ? (
          <Button
            variant="contained"
            className="TopbarButton"
            onClick={() => user.setIsOpenConnectWallet(true)}
            color="secondary"
            style={{
              whiteSpace: "pre",
              position: "relative",
              padding: "10px 25px 10px 50px",
            }}
          >
            <img
              src="images/buttonicon_2.png"
              alt="iamge"
              className="centerImg"
            />{" "}
            Connect Wallet
          </Button>
        ) : (
          <Button
            variant="contained"
            className="TopbarButton"
            color="secondary"
            onClick={() => user.disconnectWallet()}
            style={{
              whiteSpace: "pre",
              position: "relative",
              padding: "10px 25px 10px 50px",
            }}
          >
            <img
              src="images/buttonicon_2.png"
              alt="iamge"
              className="centerImg"
            />{" "}
            {account && sortAddress(account)}
          </Button>
        )}
        {location.pathname === "/" && mobileView && (
          <Box style={{ marginTop: "4px" }}>
            <AddCustomToken />
          </Box>
        )}
      </>
    );
  };
  const femmecubatorLogo = (
    <Box>
      <Link to="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  );
  return (
    <>
      <AppBar
        position={history.location.pathname !== "/" ? "relative" : "absolute"}
        elevation={0}
        style={{
          backgroundColor: "transparent",
          border: "none",
        }}
        className="hedaerTopbar"
      >
        <Container maxWidth="fixed">
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
        <ConnectModal
          user={user}
          account={account}
          setOpen={() => user?.setIsOpenConnectWallet(false)}
          open={user?.isOpenConnectWallet}
        />
      </AppBar>
    </>
  );
}
