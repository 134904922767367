import React, { useState } from "react";
import {
  makeStyles,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Box,
  Grid,
  Hidden,
} from "@material-ui/core";
import { IoClose } from "react-icons/io5";
import { SUPPORTED_WALLETS } from "src/connectors";
const useStyles = makeStyles((theme) => ({
  connectModalBox: {
    padding: "30px",
    [theme.breakpoints.down("xs")]: {
      padding: "10px",
    },
    "& .imageBox": {
      position: "absolute",
      bottom: "30px",
      left: "114px",
      maxWidth: "360px",
    },
    "& .eagleBirdBox": {
      position: "absolute",
      top: "25px",
      left: "-25px",
    },
    "& h4": {
      color: "#fff",
      fontWeight: "300",
    },
    "& h6": {
      color: "rgba(255, 255, 255, 0.6)",
      fontWeight: "300",
      marginTop: "12px",
    },
    "& p": {
      color: "red",
    },
    "& .gridFlex": {
      padding: "30px 0px",
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "0px 0px",
      },
    },
  },
  walletBox: {
    display: "flex",
    justifyContent: "space-between",
    "& .Wallet": {
      background: "rgb(21 20 20)",
      padding: "31px 40px",
      borderRadius: "14px",
      // minWidth: "113px",
      marginRight: "9px",
      marginTop: "24px",
      border: "1px solid #2c2c2c",
      cursor: "pointer",
      "& h5": {
        marginTop: "16px",
        color: "#fff",
        whiteSpace: "pre",
        [theme.breakpoints.down("xs")]: {
          fontSize: "14px !important",
          marginTop: "6px",
        },
      },
      [theme.breakpoints.down("xs")]: {
        padding: "20px",
        marginLeft: "5px",
      },
      "& img": {
        [theme.breakpoints.down("xs")]: {
          maxWidth: "35px",
          width: "auto",
        },
      },
    },
  },
  cancelBtn: {
    position: "absolute",
    top: 0,
    right: 0,
    "& svg": {
      color: "#fff",
      fontWeight: "700",
    },
  },
}));

function ConnectModal({ user, open, setOpen, account }) {
  const classes = useStyles();
  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogContent className="borderShadowBox">
          <Box className={classes.connectModalBox}>
            <Grid container spacing={5}>
              <Grid item xs={12} sm={6} md={6}>
                <Box className="gridFlex">
                  <Box>
                    <img src="images/logo.png" />
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Box className="gridFlex">
                  <Box>
                    <Typography className="ubuntu" variant="h4">
                      Connect your Wallet
                    </Typography>
                  </Box>

                  <Box className={classes.walletBox}>
                    {SUPPORTED_WALLETS.map((item, i) => {
                      return (
                        <Box
                          align="center"
                          className="Wallet"
                          key={i}
                          onClick={() => {
                            if (account) {
                              user.disconnectWallet();
                              localStorage.removeItem("walletName");
                            } else {
                              user.connectWallet(item.data);
                              user.setIsOpenConnectWallet(false);
                            }
                          }}
                        >
                          <img width="45px" src={item.data.iconName} />
                          <Typography className="ubuntu" variant="h5">
                            {item.data.name}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ConnectModal;
