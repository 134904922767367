import React, { useState, Fragment } from "react";
import { Button } from "@material-ui/core";
import { currencyUnit, lotteryContract } from "src/constants";
import detectEthereumProvider from "@metamask/detect-provider";
import lotteryABI from "src/ABI/lotteryABI.json";
import tokenABI from "src/ABI/tokenABI.json";
import { getContract } from "src/utils";
import { useWeb3React } from "@web3-react/core";
import { toast } from "react-hot-toast";
import ScreenLoader from "../ScreenLoader";

export default function AddCustomToken() {
  const { account, library } = useWeb3React();
  const [isProcessing, setIsProcessing] = useState(false);

  //function invoking metamask to add custom token to the users wallet
  const addTokenHandler = async ({ tokenAddress, tokenName }) => {
    const provider = await detectEthereumProvider();
    provider.sendAsync(
      {
        method: "metamask_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenName,
            decimals: 18,
            image: "",
          },
        },
        id: Math.round(Math.random() * 100000),
      },
      (err, added) => {
        console.log("provider returned", err, added);
        if (err || "error" in added) {
          return false;
        } else {
          return true;
        }
      }
    );
  };

  //function to get the token address and token name from the contract and adding them in to the user's wallet
  const addNativeTokenHandler = async () => {
    try {
      setIsProcessing(true);
      const createObj = getContract(
        lotteryContract,
        lotteryABI,
        library,
        account
      );
      const address = await createObj.cakeToken();
      const tokenObj = getContract(address, tokenABI, library, account);
      const name = await tokenObj.name();

      //if you are not fetching tokenAddress and tokenName from the contract, you can pass them statically.
      await addTokenHandler({
        tokenAddress: address,
        tokenName: name,
      });
      setIsProcessing(false);
    } catch (error) {
      console.log(error);
      setIsProcessing(false);
    }
  };

  return (
    <Fragment>
      <Button
        variant="contained"
        color="secondary"
        style={{ whiteSpace: "pre" }}
        onClick={addNativeTokenHandler}
      >
        {`Add ${currencyUnit} to Wallet`}
      </Button>
      {isProcessing && (
        <ScreenLoader
          open={isProcessing}
          close={() => setIsProcessing(false)}
        />
      )}
    </Fragment>
  );
}
