export const baseURL = "https://node-ecosystem.mobiloitte.io"; // staging server
// export const baseURL = "https://node.bbhtesting.com"; // development server
// export const baseURL = "http://172.16.1.224:1992"; // local server

let versionControll = "api/v1";
let user = `${baseURL}/${versionControll}/user`;
let lottery = `${baseURL}/${versionControll}/lottery`;

const apiConfigs = {
  connectWallet: `${user}/connectWallet`,

  //LOTTERY//
  createRoom: `${lottery}/createRoom`,
  buyTickets: `${lottery}/buyTickets`,
  onGoingEvent: `${lottery}/onGoingEvent`,
  createLottory: `${lottery}/createLottory`,
  listLottory: `${lottery}/listLottory`,
};
export default apiConfigs;
