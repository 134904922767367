import React, { createContext, useEffect, useState } from "react";
import { SUPPORTED_WALLETS, injected } from "src/connectors";
import { useWeb3React } from "@web3-react/core";
import { getContract, getWeb3Obj } from "src/utils";
import { ACTIVE_NETWORK, lotteryContract, NetworkDetails } from "src/constants";
import lotteryABI from "src/ABI/lotteryABI.json";
import tokenABI from "src/ABI/tokenABI.json";
import { getOnGoingLotteryDataBlockchain } from "src/api/CurrentLotteryData";
import { postAPIHandler } from "src/apiConfig/services";
export const UserContext = createContext();

const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem("userAddress", userAddress);
  } else {
    sessionStorage.removeItem("userAddress");
  }
};

export default function AuthProvider(props) {
  const { activate, account, chainId, deactivate, library } = useWeb3React();
  const [yourWalletBalance, setYourWalletBalance] = useState(0);
  const [tokenBalance, settokenBalance] = useState(0);
  const [isUpdated, setIsUpdated] = useState(false);
  const [currentLotteryData, setCurrentLotteryData] = useState({
    currentTicketValue: "",
    ticketData: "",
    allData: {},
    ticketNumber: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenConnectWallet, setIsOpenConnectWallet] = useState(false);

  //-----*WEB3-Connection-Establisher*-----//
  const connectWalletHandler = (data) => {
    try {
      const connector = data?.connector;
      localStorage.setItem("walletName", data?.name);
      sessionStorage.removeItem("walletName");
      if (connector && connector.walletConnectProvider?.wc?.uri) {
        connector.walletConnectProvider = undefined;
      }
      activate(connector, undefined, true).catch((error) => {
        if (error) {
          localStorage.removeItem("walletName");
          // activate(connector);
          console.log("error", error);
        }
      });
    } catch (error) {
      console.log("ERROR", error);
      // toast.error(JSON.stringify(error.message));
    }
  };

  //----ConnectWalletAPiHandler----//
  const connectWalletApiHandler = async (account) => {
    try {
      const response = await postAPIHandler({
        endPoint: "connectWallet",
        dataToSend: {
          walletAddress: account,
        },
      });
      if (response.data.responseCode === 200) {
        window.localStorage.setItem("token", response.data.result.token);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //-----*WEB3-Connection-Disable*-----//
  const disconnectWalletHandler = async () => {
    try {
      deactivate();
      // toast.success("You have been disconnected successfully!");
      window.localStorage.removeItem("userType");
      window.localStorage.removeItem("walletName");
      window.location = "/";
    } catch (error) {
      console.log(error);
    }
  };
  // -------* wallet token *---------//

  const getTokenWalletBalance = async () => {
    const contractObjToken = getContract(
      lotteryContract,
      lotteryABI,
      library,
      account
    );
    const approveToken = await contractObjToken.cakeToken();
    const tokenObj = getContract(approveToken, tokenABI, library, account);
    const web3 = await getWeb3Obj();
    const getTokenBalance = await tokenObj.balanceOf(account);

    const toStngBl = getTokenBalance?.toString();
    const balancetoken = web3.utils.fromWei(toStngBl);

    settokenBalance(parseFloat(balancetoken).toFixed(2));
  };
  // account balance -----//
  const getUserbalce = async () => {
    const web3 = await getWeb3Obj();
    const balance = await web3.eth.getBalance(account);
    const balanceImETH = await web3.utils.fromWei(balance);
    setYourWalletBalance(parseFloat(balanceImETH).toFixed(2));
  };

  //-----*WEB3-Network-Change-Request*-----//
  const swichNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x" + ACTIVE_NETWORK.toString(16) }],
      });
    } catch (error) {
      console.log("ERROR", error);
      if (error.code === 4902) {
        addNetworkHandler();
      }
    }
  };
  //-----*WEB3-Network-Add-Request*-----//
  const addNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: NetworkDetails,
      });
    } catch (error) {
      console.log("ERROR", error);
      // toast.warn(error.message);
    }
  };
  const getGlobalLotteryData = async () => {
    try {
      setIsLoading(true);
      const response = await getOnGoingLotteryDataBlockchain({
        account: account,
        library: library,
        page: "",
      });
      setIsLoading(false);
      setCurrentLotteryData(response);
      setIsUpdated(!isUpdated);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  let data = {
    isLoading,
    isUpdated,
    currentLotteryData,
    isOpenConnectWallet,
    setIsOpenConnectWallet: (item) => setIsOpenConnectWallet(item),
    updateUser: (account) => {
      setSession(account);
    },
    setIsLoading: (data) => setIsLoading(data),
    getGlobalLotteryData: () => getGlobalLotteryData(),
    connectWallet: (item) => connectWalletHandler(item),
    disconnectWallet: () => {
      disconnectWalletHandler();
      // setIsLoading(false);
    },
    yourWalletBalance,
    tokenBalance,
  };

  useEffect(() => {
    data.updateUser(account);
    getGlobalLotteryData();
    if (account) {
      setIsOpenConnectWallet(false);
      connectWalletApiHandler(account);
    }
  }, [account, chainId]); //eslint-disable-line

  useEffect(() => {
    data.updateUser(account);
    if (account) {
      getTokenWalletBalance();
      getUserbalce();
    }
  }, [account]); //eslint-disable-line
  useEffect(() => {
    if (account && chainId) {
      if (chainId !== ACTIVE_NETWORK) {
        window.scrollTo(0, 0);
        if (window.ethereum) {
          swichNetworkHandler("hey");
        }
      }
    }
  }, [chainId, account]); //eslint-disable-line
  useEffect(() => {
    if (localStorage.getItem("walletName")) {
      const selectectWalletDetails = SUPPORTED_WALLETS.filter((data) => {
        return data?.data?.name == localStorage.getItem("walletName");
      });
      if (selectectWalletDetails.length > 0) {
        connectWalletHandler(selectectWalletDetails[0].data);
      }
    }
  }, [localStorage.getItem("walletName")]);

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}
